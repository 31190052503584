import React, { useEffect } from "react";
import styled from "styled-components";
import { theme } from "../theme";
import Navigation from "../components/Landing/Navigation.section";
import Introduction from "../components/Landing/Introduction.section";
import Catrgories from "../components/Landing/Catrgories.section";
import Benefits from "../components/Landing/Benefits.section";
import Details from "../components/Landing/Details.section";
import Footer from "../components/Landing/Footer.section";
import About from "../components/Landing/About.section";
import Aos from "aos";
import "aos/dist/aos.css";
import Advantages from "../components/Landing/Advantages.section";
import Partner from "../components/Landing/Partner.section";

const Landing = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
    });
    Aos.refresh();
  }, []);

  return (
    <Container>
      <Navigation />
      <Introduction />
      <About />
      <Advantages />
      {/* <Catrgories /> */}
      <Benefits />
      <Details />
      <Partner />
      <Footer />
    </Container>
  );
};

const Container = styled.nav`
  background-color: ${theme.background};
  min-height: 100vh;
  padding-top: 130px;
  background: none;
  font-size: 20px;
  width: 100vw;
  overflow-x: hidden;
`;

export default Landing;
