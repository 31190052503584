import React from "react";
import styled from "styled-components";
import { GlobalButton, RowColumns, theme } from "../../theme";
import { Catch, Circle } from "./Introduction.section";

const Partner = () => {
  const handleEmailClick = (subject: string) => {
    window.location.href = `mailto:cedservices2024@gmail.com?subject=${encodeURIComponent(subject)}`;
  };

  return (
    <Container id="partnership">
      <Wrapper>
        <Catch
          style={{
            color: theme.font,
            fontSize: 40,
            margin: 0,
            lineHeight: 1.2,
            marginBottom: 10,
          }}
          data-aos="fade-up"
        >
          Let's Work Together As <br />
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>
            Partner
          </span>{" "}
          Or As{" "}
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>
            Rider
          </span>
        </Catch>
        <Briefing data-aos="fade-up">
          Join our network of 10,000+ partner shops and expand your reach across
          Accra. Boost your sales with our reliable delivery infrastructure.
        </Briefing>
      </Wrapper>
      <OptionsContainer>
        <RowColumns width="33%" data-aos="fade-right">
          <Card style={{ minHeight: 400, backgroundColor: theme.orange }}>
            <Circle
              style={{
                top: "-10%",
                left: "80%",
                backgroundColor: "rgba(0,0,0,0.2)",
              }}
            />
            <Catch
              style={{
                color: theme.background,
                fontSize: 40,
                lineHeight: 1,
                margin: 0,
                position: "relative",
                zIndex: 1,
              }}
            >
              Businesses
            </Catch>
            <DicountImg
              src={require("../../assets/partner.png")}
              height={450}
            />
          </Card>
          <Briefing style={{ padding: 0, marginBottom: 20 }}>
            Join our 10,000+ partner network and boost your sales with zero
            setup costs and real-time order tracking.
          </Briefing>
          <GlobalButton 
            background={theme.orange} 
            color={theme.background} 
            hoverColor="black" 
            style={{marginTop: 0}}
            onClick={() => handleEmailClick("Business Partnership Inquiry")}
          >
            Let's Get Started
          </GlobalButton>
        </RowColumns>
        <RowColumns width="33%" data-aos="fade-up">
          <Card style={{ minHeight: 400, backgroundColor: theme.font }}>
            <Circle
              style={{
                top: "-20%",
                left: "-10%",
                backgroundColor: "rgba(255, 255, 255,0.2)",
              }}
            />
            <Catch
              style={{
                color: theme.background,
                fontSize: 40,
                lineHeight: 1,
                margin: 0,
                position: "relative",
                zIndex: 1,
              }}
            >
              Rider
            </Catch>
            <DicountImg src={require("../../assets/rider.png")} height={450} />
          </Card>
          <Briefing style={{ padding: 0, marginBottom: 20 }}>
            Earn competitively with flexible hours while being part of our
            professional delivery team. Join us today and start earning.
          </Briefing>
          <GlobalButton 
            background={theme.font} 
            color={theme.background} 
            hoverColor="black" 
            style={{marginTop: 0}}
            onClick={() => handleEmailClick("Rider Application")}
          >
            Let's Get Onboarded
          </GlobalButton>
        </RowColumns>
        <RowColumns width="33%" data-aos="fade-left">
          <Card style={{ minHeight: 400, backgroundColor: theme.yellow }}>
            <Circle
              style={{
                top: "80%",
                left: "-10%",
                backgroundColor: "rgba(255, 255, 255,0.2)",
              }}
            />
            <Catch
              style={{
                color: theme.background,
                fontSize: 40,
                lineHeight: 1,
                margin: 0,
                position: "relative",
                zIndex: 1,
              }}
            >
              Corporate Solutions
            </Catch>
            <DicountImg
              src={require("../../assets/coporate.png")}
              height={450}
            />
          </Card>
          <Briefing style={{ padding: 0, marginBottom: 20 }}>
            Get dedicated riders and priority support with custom delivery
            packages designed for your business needs.
          </Briefing>
          <GlobalButton 
            background={theme.yellow} 
            color={theme.font} 
            style={{marginTop: 0}}
            onClick={() => handleEmailClick("Corporate Solutions Inquiry")}
          >
            Let's Start Working
          </GlobalButton>
        </RowColumns>
      </OptionsContainer>
    </Container>
  );
};

const Container = styled.section`
  padding: 5% 7.5%;

  @media (max-width: 768px) {
    padding: 0 6%;
  }
`;

const Wrapper = styled.div`
  padding: 4% 4% 0 4%;
  border-radius: 30px;
  min-height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding-top: 50px;
  }
`;

const Briefing = styled.p`
  color: ${theme.font};
  font-size: 16px;
  font-weight: 500;
  padding: 0 15%;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  gap: 4%;

  @media (max-width: 768px) {
    gap: 20px;
    flex-direction: column;
  }
`;

const Card = styled.div`
  width: 100%;
  border-radius: 25px;
  padding: 6%;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const DicountImg = styled.img`
  position: absolute;
  top: -10%;
  right: -20%;
  z-index: 0;

  @media (max-width: 768px) {
    right: -30%;
  }
`;

const StyledLink = styled.a`
  font-size: 30px;
  text-decoration: none;
  color: ${theme.font};
  font-family: inherit !important;
`;

export default Partner;
