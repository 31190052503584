import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HeaderRow } from "./Catrgories.section";
import { Catch } from "./Introduction.section";
import { colors, RowColumns, theme } from "../../theme";
import ImageGrid from "./ImageGrid.section";
import { FaShippingFast } from "react-icons/fa";
import { PiPackageFill } from "react-icons/pi";
import { PiQuestionMarkBold } from "react-icons/pi";
import electronic from "../../assets/images/electronic.jpg";
import handling from "../../assets/images/handling.jpg";
import market from "../../assets/images/market.jpg";

const About = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [tempImageIndex, setTempImageIndex] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const images = [market, handling, electronic];

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (!isLocked) {
      interval = setInterval(() => {
        setFadeOut(true);
        setTimeout(() => {
          setCurrentImageIndex((prevIndex) => 
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
          );
          setTempImageIndex((prevIndex) => 
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
          );
          setFadeOut(false);
        }, 500);
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [isLocked]);

  const handleCardHover = (index: number) => {
    if (!isLocked) {
      setFadeOut(true);
      setTimeout(() => {
        setTempImageIndex(index);
        setFadeOut(false);
      }, 500);
    }
  };

  const handleCardLeave = () => {
    if (!isLocked) {
      setFadeOut(true);
      setTimeout(() => {
        setTempImageIndex(currentImageIndex);
        setFadeOut(false);
      }, 500);
    }
  };

  const handleCardClick = (index: number) => {
    setFadeOut(true);
    setIsLocked(true);
    setTimeout(() => {
      setCurrentImageIndex(index);
      setTempImageIndex(index);
      setFadeOut(false);
    }, 500);

    setTimeout(() => {
      setIsLocked(false);
    }, 10000);
  };

  return (
    <Container id="categories">
      <RowColumns width="50%">
        <Image 
          src={images[tempImageIndex]}
          style={{
            opacity: fadeOut ? 0 : 1,
            transition: "opacity 0.3s ease-in-out"
          }}
          data-aos="fade-right"
          data-aos-duration="1000"
        />
      </RowColumns>
      <RowColumns
        width="50%"
        style={{ display: "flex", flexDirection: "column", gap: 20 }}
      >
        <Catch
          style={{
            fontSize: 50,
            margin: 0,
          }}
        >
          Who We{" "}
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>
            Are
          </span>
          <br />& What We{" "}
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>Do</span>
        </Catch>
        <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
          <ServiceComponent
            title="Who We Are at CED"
            description="Based in Accra, Ghana, CED delivers more than items—we deliver an experience. With real-time tracking and professional riders, we ensure reliable, quick, and convenient service tailored to your needs."
            icon={<PiQuestionMarkBold />}
            background="white"
            onHover={() => handleCardHover(0)}
            onLeave={handleCardLeave}
            onClick={() => handleCardClick(0)}
            isActive={tempImageIndex === 0}
          />
          <ServiceComponent
            title="Effortless Pickups & Packaging"
            description=" Enjoy simple doorstep pickups and premium packaging. We prioritize keeping your items in perfect condition, making delivery stress-free."
            icon={<PiPackageFill />}
            background="orange"
            onHover={() => handleCardHover(1)}
            onLeave={handleCardLeave}
            onClick={() => handleCardClick(1)}
            isActive={tempImageIndex === 1}
          />
          <ServiceComponent
            title="Fast, Reliable Delivery Service in Accra"
            description="Experience the fastest delivery with Cutting-Edge Delivery Service (CED). We handle food, documents, and gadgets with care, ensuring swift and secure doorstep service."
            icon={<FaShippingFast />}
            background="black"
            onHover={() => handleCardHover(2)}
            onLeave={handleCardLeave}
            onClick={() => handleCardClick(2)}
            isActive={tempImageIndex === 2}
          />
        </div>
      </RowColumns>
    </Container>
  );
};

const ServiceComponent = ({
  title,
  description,
  icon,
  background = "orange",
  onHover,
  onLeave,
  onClick,
  isActive,
}: {
  title: string;
  description: string;
  icon: any;
  background?: "orange" | "black" | "white";
  onHover: () => void;
  onLeave: () => void;
  onClick: () => void;
  isActive: boolean;
}) => {
  return (
    <ServiceCard
      background={background}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      onClick={onClick}
      isActive={isActive}
      data-aos="fade-up"
    >
      <div>
        <Title background={background}>{title}</Title>
        <Description background={background}>{description}</Description>
      </div>
      <div
        style={{
          minWidth: 50,
          minHeight: 50,
          width: 50,
          height: 50,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            background === "orange"
              ? "white"
              : background === "white"
              ? theme.accent_gray
              : "white",
          padding: 8,
          flexShrink: 0,
        }}
      >
        {React.cloneElement(icon, {
          size: 20,
          color:
            background === "orange"
              ? theme.orange
              : background === "white"
              ? "black"
              : theme.font,
        })}
      </div>
    </ServiceCard>
  );
};

const ServiceCard = styled.div<{ background: string; isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 14px 18px;
  border-radius: 12px;
  background-color: ${props =>
    props.background === "orange"
      ? theme.orange
      : props.background === "white"
      ? "white"
      : theme.font};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${props =>
      props.background === "orange"
        ? `${theme.orange}dd`
        : props.background === "white"
        ? '#f5f5f5'
        : `${theme.font}dd`};
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    transform: translateY(-2px);
  }
`;

const Container = styled.div`
  padding: 5% 7.5%;
  display: flex;
  gap: 4%;
  margin-top: 5rem;
  background: linear-gradient(to bottom, ${theme.orange_light} 0%, ${theme.orange_light} 30%, white 70%, white 100%);

  @media (max-width: 768px) {
    margin-top: 1.5rem;
    padding: 0 6%;
    flex-direction: column;
  }
`;

const Title = styled.h4<{ background: string }>`
  margin: 0;
  font-size: 18px;
  margin-bottom: 10px;
  font-family: "Yrsa", serif;
  color: ${(props) => (props.background === "white" ? "black" : "white")};
`;

const Description = styled.p<{ background: string }>`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => (props.background === "white" ? "black" : "white")};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;

  @media (max-width: 768px) {
    height: 300px;
    margin: 20px 0;
  }
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

export default About;
