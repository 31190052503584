import usePath from "../../hooks/usePath";
import styled from "styled-components";
import { theme, transition } from "../../theme";
import { Link as RouterLink } from "react-router-dom";
import { useEffect } from "react";
import { scrollIntoViewWithOffset } from "../../services/scroll.service";

const Navigation = () => {
  const { params } = usePath();

  useEffect(()=>{
    if(params["section"])
      scrollIntoViewWithOffset(`#${params["section"]}`, 100)
  }, [params])

  return (
    <Container>
      <Wrapper>
        <Icon src={require("../../assets/logo.png")} alt="logo" />
        <LinksWrapper>
          <Link to={"/?section=services"}>
            <div>
              Services
              <Indicator
                style={{
                  background:
                    params["section"] === "services"
                      ? theme.yellow
                      : "transparent",
                  width: params["section"] === "services" ? "70%" : 0,
                }}
              />
            </div>
          </Link>
          <Link to={"/?section=categories"}>
            <div>
              Categories
              <Indicator
                style={{
                  background:
                    params["section"] === "categories"
                      ? theme.yellow
                      : "transparent",
                  width: params["section"] === "categories" ? "70%" : 0,
                }}
              />
            </div>
          </Link>
          <Link to={"?section=advantages"}>
            <div>
              Advantages
              <Indicator
                style={{
                  background:
                    params["section"] === "advantages"
                      ? theme.yellow
                      : "transparent",
                  width: params["section"] === "advantages" ? "70%" : 0,
                }}
              />
            </div>
          </Link>
          <Link to={"?section=discounts"}>
            <div>
              Discounts
              <Indicator
                style={{
                  background:
                    params["section"] === "discounts"
                      ? theme.yellow
                      : "transparent",
                  width: params["section"] === "discounts" ? "70%" : 0,
                }}
              />
            </div>
          </Link>
          <Link to={"?section=partnership"}>
            <div>
              Partnership
              <Indicator
                style={{
                  background:
                    params["section"] === "partnership"
                      ? theme.yellow
                      : "transparent",
                  width: params["section"] === "partnership" ? "70%" : 0,
                }}
              />
            </div>
          </Link>
        </LinksWrapper>
        <Link to="tel:+233554644586">
          <Contact>
            <img
              src={require("../../assets/icons/abena.png")}
              height={40}
              alt="Abena"
            />
            Support
          </Contact>
        </Link>
      </Wrapper>
    </Container>
  );
};

const Container = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 6%;
  z-index: 2;
`;

const Icon = styled.img`
  width: 50px;

  @media (max-width: 768px) {
    width: 40px;
  }
`;

const Wrapper = styled.div`
  border-radius: 0 0 30px 30px;
  background-color: ${theme.font};
  color: ${theme.background};
  padding: 15px 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;

  @media (max-width: 768px) {
    padding: 10px 14px 14px 14px;
  }
`;

const Contact = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  padding: 5px;
  padding-right: 10px;
  border: none;
  outline: none;
  transition: ${transition};
  cursor: pointer;
  color: ${theme.background};
  font-size: 15px;
  border-radius: 30px;
  font-weight: 600;

  &:hover {
    background-color: #474747;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  font-size: 15px;
  color: ${theme.background};
  transition: ${transition};

  &:hover {
    color: ${theme.accent};
  }
`;

const Indicator = styled.span`
  display: block;
  height: 2px;
  border-radius: 10px;
  margin-top: 8px;
  transition: ${transition};
`;

export default Navigation;
