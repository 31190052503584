import React from "react";
import { Wave } from "./Benefits.section";
import styled from "styled-components";
import wavesBackground from "../../assets/svg/layered-waves-haikei-footer.svg";
import { GlobalButton, Link, theme } from "../../theme";
import { CiFries } from "react-icons/ci";
import { PiBowlFoodLight } from "react-icons/pi";
import { Catch } from "./Introduction.section";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoYoutube } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareFacebook } from "react-icons/fa6";

const Footer = () => {
  const highlightStyles: React.CSSProperties = {
    color: "rgba(255, 255, 255, 0.1)",
    height: 60,
    width: 60,
    position: "absolute",
  };

  return (
    <>
      <WaveFooter />
      <Container>
        <CiFries style={{ ...highlightStyles, top: 30, left: 31 }} />
        <PiBowlFoodLight style={{ ...highlightStyles, top: 30, right: 31 }} />
        <CiFries style={{ ...highlightStyles, bottom: 30, right: 31 }} />
        <PiBowlFoodLight style={{ ...highlightStyles, bottom: 30, left: 31 }} />
        <BusinessWrapper>
          <BusinessIcon
            src={require("../../assets/pizza.png")}
            style={{ margin: "0 0 20px 20px" }}
          />
          <BusinessIcon src={require("../../assets/smart_watch.png")} />
        </BusinessWrapper>
        <MessageDiv>
          <Catch
            style={{
              color: theme.background,
              fontSize: 40,
              margin: 0,
              lineHeight: 1.2,
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            Enjoy pickups from
            <br />{" "}
            <span style={{ color: theme.orange, fontFamily: "inherit" }}>
              10000+ shops
            </span>
            <br /> in the city of accra
          </Catch>
          <Link to="tel:+233554644586">
            <GlobalButton
              style={{
                backgroundColor: theme.background,
                color: theme.font,
                marginTop: 20,
              }}
            >
              Contact support <IoCall />
            </GlobalButton>
          </Link>
        </MessageDiv>
        <BusinessWrapper>
          <BusinessIcon src={require("../../assets/documents.png")} />
          <BusinessIcon
            src={require("../../assets/clothes.webp")}
            style={{ margin: "20px 0 0 20px" }}
          />
        </BusinessWrapper>
      </Container>
      <ContainerBottom>
        <WrapperBottom>
          <Icon src={require("../../assets/logo_dark.png")} alt="logo" />
          <span
            style={{
              fontSize: 13,
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            @{" "}
            <span
              style={{
                fontWeight: "bold",
                color: theme.orange,
              }}
            >
              {new Date().getFullYear()}
            </span>{" "}
            | All Rights Reserved • Powered by{" "}
            <a
              href="https://cwintechsolutions.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CedIcon src={require("../../assets/ced_logo.png")} alt="logo" />
            </a>
          </span>
          <span
            style={{
              display: "flex",
              gap: 15,
              alignItems: "center",
              fontSize: 24,
            }}
          >
            <a
              href="https://www.instagram.com/ced_services?igsh=ZjF4czAxd2pvcG1u"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillInstagram style={{ color: theme.font }} />
            </a>
            <a
              href="https://youtube.com/@cedservices?si=7FK2_pLdLy1VeLpj"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoLogoYoutube style={{ color: theme.font }} />
            </a>
            <a
              href="https://www.facebook.com/share/1BgjqGgvdc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaSquareFacebook style={{ color: theme.font }} />
            </a>
          </span>
        </WrapperBottom>
      </ContainerBottom>
    </>
  );
};

const WaveFooter = styled(Wave)`
  background-image: url(${wavesBackground});
`;

const Container = styled.div`
  min-height: 300px;
  background-color: ${theme.font};
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding-top: 40px;
  }
`;

const MessageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BusinessIcon = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-color: ${theme.background};

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

const BusinessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5% 10% 5% 10%;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const ContainerBottom = styled.footer`
  width: 100%;
  padding: 0 6%;
  background-color: ${theme.font};
`;

const Icon = styled.img`
  width: 50px;

  @media (max-width: 768px) {
    width: 40px;
  }
`;

const CedIcon = styled.img`
  width: 30px;

  @media (max-width: 768px) {
    width: 20px;
  }
`;

const WrapperBottom = styled.div`
  border-radius: 30px 30px 0 0;
  background-color: ${theme.background};
  color: ${theme.font};
  padding: 20px 20px 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 15px 14px 16px 14px;
  }
`;

export default Footer;
