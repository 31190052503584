import React from "react";
import styled from "styled-components";
import { theme } from "../../theme";
import { Catch, Description } from "./Introduction.section";
import { CiDeliveryTruck } from "react-icons/ci";
import { GiPayMoney } from "react-icons/gi";
import { BiSupport } from "react-icons/bi";
import { GiOfficeChair } from "react-icons/gi";
import { PiSmileyWinkThin } from "react-icons/pi";

const Advantages = () => {
  return (
    <Container id="advantages">
      <Wrapper>
        <Catch
          style={{
            color: theme.background,
            fontSize: 40,
            margin: 0,
            lineHeight: 1.2,
            marginBottom: 10,
          }}
        >
          Why Choose{" "}
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>
            CED?
          </span>
        </Catch>
        <Briefing>
          Your time matters, and so does your peace of mind. At CED, we've
          revolutionized delivery services in Accra by combining speed,
          reliability, and innovation. Our commitment to excellence shows in
          every delivery we make, whether it's your lunch, crucial documents, or
          essential electronics. Here's why thousands of customers trust us
          daily:
        </Briefing>
        <PointsWrapper>
          <Points
            title="Lightning-Fast Delivery"
            description="From food to documents, experience 30-minute delivery within Accra's business district."
            icon={<CiDeliveryTruck size={50} />}
          />
          <Points
            title="Cost-Effective Solutions"
            description="Save up to 60% with our multi-order system and enjoy competitive rates starting at GH₵15."
            icon={<GiPayMoney size={50} />}
          />
          <Points
            title="Professional Care"
            description="Our trained riders handle your items with utmost care, from temperature-controlled food delivery to secure document handling."
            icon={<GiOfficeChair size={50} />}
          />
          <Points
            title="Convenience Redefined"
            description="Schedule deliveries 24/7 through our user-friendly platform - because convenience shouldn't have time limits."
            icon={<PiSmileyWinkThin size={50} />}
          />
        </PointsWrapper>
      </Wrapper>
    </Container>
  );
};

const Points = ({
  icon,
  title,
  description,
}: {
  icon: any;
  title: string;
  description: string;
}) => {
  return (
    <PointsContainer data-aos="fade-up">
      <PointsTop>
        <IconWrapper>{icon}</IconWrapper>
        <PointsHeader>{title}</PointsHeader>
        <PointsDesc>{description}</PointsDesc>
      </PointsTop>
    </PointsContainer>
  );
};

const Container = styled.section`
  padding: 5% 7.5%;
`;

const Wrapper = styled.div`
  padding: 4% 4% 0 4%;
  border-radius: 30px;
  background-color: ${theme.font};
  min-height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 15px;
  }
`;

const Briefing = styled.p`
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding: 0 15%;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0;
  }
`;

const PointsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 15px;

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

const PointsTop = styled.div`
  border-radius: 40% 40% 0 0;
  background-color: white;
  padding: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 30px;
  }
`;

const PointsContainer = styled.div`
  color: ${theme.font};
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    color: ${theme.orange};
    
    ${PointsTop} {
      /* background-color: ${theme.orange}; */
      color: ${theme.orange};
    }
  }
`;

const IconWrapper = styled.div`
  color: inherit;
  transition: color 0.3s ease;
`;



const PointsHeader = styled.h3`
  font-size: 16px;
  margin: 15px 0 10px 0;
  color: inherit;
`;

const PointsDesc = styled(Briefing)`
  padding: 0;
  color: inherit;
  font-size: 12px;
  margin: 0;
`;

export default Advantages;
